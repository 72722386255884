<template>
  <section class="tables">
    
    <div class="page-header">
      <h3 class="page-title">Seo Management </h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link class="btn btn btn-add btn-primary" :to="`/website-management/add-seo`"
            >Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Url</th>
                  <th>Meta Title</th>
                  <th>keyword</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="seo">
                <tr v-for="(item, index) in seo" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.url }}</td>
                  <td>{{ item.meta_title }}</td>
                  <td>{{ item.meta_keyword }}</td>
                  <td>
                    <router-link
                       class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/website-management/edit-seo/${item.id}`"
                    >
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <a v-on:click="deleteFile(item.id)"  class="mr-2 table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";

export default {
  name: "seo",
  components: {
    
  },
  data() {
    return {
      
      seo:""
    };
  },
  mounted() {
    this.getseos();
  },
  methods: {
    async getseos() {
      
      const { data } = await setingsApi.getSeo();
      this.seo = data.data.data;
      
    },
    deleteFile(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          
          const response = setingsApi.deleteSeo(id).then((response) => {
            
            if (response.data.code == 200) {
              this.getseos()
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
    
  },
};
</script>

<style scoped></style>


